<template>
  <div class="md:max-w-[453px]">
    <ClinicMapSearchBox
      :showClose="showClose"
      :showSearchBox="showSearchBox"
      @onCloseClick="onCloseClicked"
      @onSearchClick="onSearchClicked"
    />
    <ClientOnly>
      <ClinicTabView
        v-if="isMobile && (showSearchBox || showTabView)"
        :tabValue="false"
        @onTabChange="onTabClicked"
      ></ClinicTabView>
      <div class="transition-all md:text-left text-center" v-if="isMobile && (showMap || showClose)">
        <div class="pb-2">
          <span class="font-semibold"> {{ mapText }} </span>
        </div>
      </div>
    </ClientOnly>
    <div v-if="isCityPage" class="font-semibold mb-5">
      {{ $t("city.clinicListTitle") }}
    </div>
    <div
      class="overflow-y-auto bg-secondary-lighter border-t-2 border-light-silver"
      id="clinicList"
      :class="listHeight"
    >
      <TransitionRoot
        :show="!showClose && showSearchBox && popularCityList.length > 0"
      >
        <div class="bg-white p-8 shadow-default mt-8 hidden md:block">
          <div class="flex space-x-4 rtl:space-x-reverse mb-8">
            <div class="flex self-start">
              <NuxtImg
                loading="lazy"
                src="/icons/pin.svg"
                alt="spin icon"
                width="27"
                height="35"
              />
            </div>
            <div class="flex flex-col space-y-4">
              <h3>{{ $t("clinic.cityTitle") }}</h3>
              <p class="text-blog-14-sm">{{ $t("clinic.citySubTitle") }}</p>
            </div>
          </div>
          <div class="font-bold px-4">
            <ClinicPopularCity
              v-for="(popularCity, index) in popularCityList"
              :key="index"
              :data="popularCity"
            >
            </ClinicPopularCity>
          </div>
        </div> 
      </TransitionRoot> 
      <ClientOnly>
     <CommonPanel
        :showModal="showModal"
     
        :isCityPage="isCityPage"
      />
      </ClientOnly>
      <div
        v-if="
          (isCityPage && isMobile && !showMap) ||
          (isCityPage && !showMap) ||
          (showClose && ((isMobile && !showMap) || !isMobile)) ||
          (!isCityPage && !showSearchBox)
        "
        class="md:h-full"
      >
        <div
          v-if="clinicLists.data == 'empty'"
          class="p-8 flex justify-center font-bold text-secondary"
        >
          {{ $t("clinic.noClinicsFound", {range: globalStore.siteSearchRange, unit: $t("distance." + distanceUnit)}) }}
        </div>

        <div v-else-if="clinicLists.length == 0 && !showClose" >
          <SkeletonClinic
            v-for="(clinicIndex, index) in mapListsToShow"
            :key="index"
          ></SkeletonClinic>
        </div>
        <div v-if="(!isCityPage && showClose) || isCityPage "> 
        <ClinicEverywhereService v-if="route.params.service !== 'clinic-test' && (!isCityPage || (isCityPage && isEveryWhereServiceActive))"></ClinicEverywhereService>
        <div v-for="(clinicIndex, index) in mapListsToShow" :key="index"    class="w-full" >
          <ClinicLocationList
            v-if="clinicLists[index]"
            :key="clinicIndex"
         
            :clinicList="clinicLists[index]"
          ></ClinicLocationList>
        </div>
        </div>
        <div
          v-if="mapListsToShow < clinicLists.length"
          class="flex justify-center py-12 relative"
        >
          <button
            class="rounded text-blog-18 text-secondary py-2 px-4 border border-secondary"
            @click.prevent="onClickNextData"
          >
            {{ $t("clinic.btnShowMoreClinics") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { TransitionRoot } from "@headlessui/vue";
import { useGlobalStore } from "~~/stores/global";
import { useMapStore } from "~~/stores/map";

const route = useRoute()
const mapStore = useMapStore();
const globalStore = useGlobalStore();
const distanceUnit = ref(globalStore.siteSettings.distance_unit.value);

const showModal = computed(() => route.query && route.query.slug?true:false);
const props = defineProps({
  isMobile: {
    type: Boolean,
  },
  showSearchBox: {
    type: Boolean,
  },
  popularCityList: {
    type: Array,
  },
  clinicLists: {
    type: [Array, Object],
  },
  mapText: {
    type: String,
    
  },
  isCityPage: {
    type: Boolean,
    defualt: false,
  },
  showTabView: {
    type: Boolean,
    default: false,
  },
  listHeight: {
    type: String,
    default: "md:h-[740px]",
  },
  clinicSlug: {
    type: String
  },
  isEveryWhereServiceActive: {
    type: Boolean,
    default: false
  }
});


const fetchClinicTests = async (id) => {
		const { data: productsList } = await useFetchClinicTests(id, 'clinic-test');
    if(productsList.value) {
      mapStore.getTests(productsList.value.data);
    }
	};


const pageLimit = ref(10);
const mapListsToShow = ref(0);
const showMap = ref(false);
const showClose = ref(false);
const loadingListOfCities = ref(false);

const emit = defineEmits([
  "selectedClinicTitle",
  "searchText",
  "clearSearch",
  "onTabClick",
]);


async function refreshClinicData(id) {
  if(id == 'everywhere-nurse-service') {
    await fetchClinicTests(id);
    mapStore.setSelectedClinic({
	   	  	id:  id,
			   title: 'Everywhere Nurse Service',
			   slug: 'everywhere',
		});
    await fetchClinicTests(id);
  } else {
    const { data: clinicDetail, refresh } = await useFetchClinicDetailBySlug(id);
    const clinic = clinicDetail.value.data;
    if (clinic) {
     mapStore.setSelectedClinic({
        id: clinic.id,
        title: clinic.display_title,
        slug: clinic.slug
      });
    await fetchClinicTests(clinic.id);
    }
  }
}

onMounted(() => {
  mapListsToShow.value = pageLimit.value;
});

watch(
  () => props.clinicSlug,
   (current) => {
    if(current) {
      refreshClinicData(current)
    }
  }, {immediate:true}
);



function onSearchClicked(data) {
  if(data){
    loadingListOfCities.value = true;
    globalStore.setClinicMapSearchText(data)
    emit("searchText", data);
    showClose.value = true;
  }
}

function onCloseClicked(lastSearchValue) {
  globalStore.setClinicMapSearchText('')
  emit("searchText", 'clearSearch');
  showClose.value = !showClose.value;
  emit("clearSearch", showClose.value);
}

function onClickNextData(e) {
  e.preventDefault();
  if (mapListsToShow.value > props.clinicLists.length) return;
  mapListsToShow.value += pageLimit.value;
}

function onTabClicked() {
  showMap.value = !showMap.value;
  emit("onTabClick", showMap.value);
}
watch(
  () => props.clinicLists,
  (prev, current) => {
    if (current.length === 0) loadingListOfCities.value = false;
  }
);


  


</script>
<style scoped>
@media screen and (min-device-width: 736px) {
  /* width */
  ::-webkit-scrollbar {
    width: 16px;
    padding: 10px;
    margin: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border: 3px solid transparent;
    border-radius: 20px;
    background-clip: content-box;
    -webkit-width: 5;
  }
}
/* Remove css once confirmed */
.pagination-underline {
  content: "";
  position: absolute;
  width: 400px;
  height: 2px;
  animation: showBox 3s ease;
}

@keyframes showBox {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 2s ease-in-out;
  transition-timing-function: ease-in-out;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
}

.skeleton {
  display: inline-block;
  background-color: #f3f3f3;
  border-radius: 4px;
  overflow: hidden;
}

.skeleton-line {
  height: 12px;
  margin-bottom: 8px;
  background-color: #e2e8f0;
}

.skeleton {
  @apply w-32 h-16;
}

.skeleton-line {
  @apply w-full;
}
</style>
