<template>
  <div>
    <div
      class="flex px-3.5 md:px-4 py-6 items-start bg-white rounded shadow-default md:mx-2 md:mt-4"
      v-if="showService"
    >
      <div class="flex w-32">
        <NuxtImg
          loading="lazy"
          src="/icons/nurse-icon.svg"
          class="w-9"
          alt="clinic icon"
          width="36"
          height="36"
        />
      </div>
      <div class="flex flex-col px-1">
        <h3 class>{{ $t("clinic.locationTitle") }}</h3>
        <p class="text-blog-14-sm py-4">{{ $t("clinic.locationSubTitle") }}</p>
        <BaseButton @buttonClicked="showLocationPanel()" class="w-32 mt-4 font-bold" text="Select"></BaseButton>
      </div>
      <div class="flex w-32 text-xs leading-[14px] mt-2">
        <span>0 {{ $t("distance." + distanceUnit) }} </span>
      </div>
    </div>
    <ClientOnly>
    	<ClinicCheckoutPanel 
      :serviceType="serviceType"
		  @closeModal="() => (openDrawer = false)" 
			:openDrawer="openDrawer">
	  </ClinicCheckoutPanel>

    </ClientOnly>
  </div>
</template>

<script setup>
import { useGlobalStore } from "~~/stores/global";
const globalStore = useGlobalStore();
import { useCheckoutStore } from "~/stores/checkout";
const showService = computed(() => globalStore.siteEverywhereService);
const addressLookup = computed(() => globalStore.siteEverywhereAddressLookup);
const serviceType = ref({});
const openDrawer = ref(false);
const distanceUnit = ref(globalStore.siteSettings.distance_unit.value);
function showLocationPanel() {
  useCheckoutStore().removeTestLists();
  if (addressLookup.value) {
    serviceType.value = { slug: "everywhere-nurse-service" };
    openDrawer.value = true;
  } else {
   // useCheckoutStore().setSelectedClinicId("everywhere");
    navigateTo(
      localeUrlQuery({
        query: {
          slug: "everywhere-nurse-service"
        }
      })
    );
  }
}
</script>
